var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('navigation',{attrs:{"flat":true}}),_c('v-main',{staticClass:"pt-0 the-main"},[(_vm.isShowPhone)?_c('training-phone',{attrs:{"content":_vm.coverPhoneData}}):_vm._e(),(!_vm.isShowPhone)?_c('cover-view',{attrs:{"data":_vm.coverData}}):_vm._e(),_c('desc-content-list',{attrs:{"content":_vm.descContent}}),_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"center","justify-content":"center"}},[_c('content-img',{attrs:{"image":require('@/assets/img/online-course-illustration.png'),"title":_vm.$t('online.onlineCourses'),"direction":"LTR","coverColor":'#f6faf7',"alignValue":'center',"features":[
            {
              content: _vm.$t('online.onlineAndOfflineDualLineCourses'),
              icon: require('@/assets/img/online-offline.svg'),
            },
            {
              content: _vm.$t('online.maximizingTeacherResources'),
              icon: require('@/assets/img/maximum-utilization.svg'),
            },
            {
              content: _vm.$t('online.teachingVideoRetention'),
              icon: require('@/assets/img/retention.svg'),
            },
          ]}})],1),_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"center","justify-content":"center"}},[_c('content-img',{attrs:{"image":require('@/assets/img/video-courses.png'),"title":_vm.$t('online.videoCourse'),"direction":"RTL","alignValue":'center',"features":[
            {
              content: _vm.$t('online.videoCourseHelp'),
              icon: require('@/assets/img/videoCourseHelp.svg'),
            },
            {
              content: _vm.$t('online.packagingCombination'),
              icon: require('@/assets/img/packagingCombination.svg'),
            },
            {
              content: _vm.$t('online.supportOpenness'),
              icon: require('@/assets/img/supportOpenness.svg'),
            },
          ]}})],1),_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"center","justify-content":"center"}},[_c('content-img',{attrs:{"image":require('@/assets/img/train-student-management.png'),"title":_vm.$t('field.studentManagement'),"descs":[_vm.$t('content.studentsGenerateIndependentFiles')],"animation-entry":"animate__fadeIn","direction":"LTR","alignValue":'center',"features":[]}})],1),_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"center","justify-content":"center"}},[_c('content-img',{attrs:{"image":require('@/assets/img/train-communication.png'),"title":_vm.$t('field.communication'),"descs":[_vm.$t('content.efficientCommunication')],"alignValue":'center',"direction":"RTL","features":[
            {
              title: _vm.$t('field.instantMessaging'),
              content: _vm.$t('content.privateCommunicationTools'),
              icon: require('@/assets/img/train-communication-chat.svg'),
            },
            {
              title: _vm.$t('field.informationRelease'),
              content: _vm.$t('content.inChatGroups'),
              icon: require('@/assets/img/train-communication-notice.svg'),
            },
            {
              title: _vm.$t('field.studentsTakingLeave'),
              content: _vm.$t('content.leaveInformationIsNotSynchronized'),
              icon: require('@/assets/img/train-communication-leave.svg'),
            },
          ]}})],1),_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"center","justify-content":"center"}},[_c('content-img',{attrs:{"image":require('@/assets/img/train-paySetting.png'),"title":_vm.$t('field.teacherFeeSetting'),"descs":[_vm.$t('content.salarySituation')],"direction":"LTR","alignValue":'center',"features":[
            {
              content: _vm.$t('content.setType'),
              icon: require('@/assets/img/train-paySetting-money.svg'),
            },
            {
              content: _vm.$t('content.billingMethod'),
              icon: require('@/assets/img/train-paySetting-person.svg'),
            },
            {
              content: _vm.$t('content.attendanceRecord'),
              icon: require('@/assets/img/train-paySetting-calendar.svg'),
            },
          ]}})],1),_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"center","justify-content":"center"}},[_c('content-img',{attrs:{"image":require('@/assets/img/train-finance.png'),"title":_vm.$t('field.financialFunctions'),"direction":"RTL","alignValue":'center',"features":[
            {
              content: _vm.$t('content.costSituation'),
              icon: require('@/assets/img/train-finance-icon1.svg'),
            },
            {
              content: _vm.$t('content.throughDataStatisticsAndAnalysis'),
              icon: require('@/assets/img/train-finance-icon2.svg'),
            },
          ]}})],1),_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"center","justify-content":"center"}},[_c('content-img',{staticStyle:{"padding":"40px 0"},attrs:{"image":require('@/assets/img/cloud-service-plan.png'),"title":_vm.$t('online.privateCloudServicePlan'),"direction":"LTR","alignValue":'center',"features":[
            {
              content: _vm.$t('online.helpBuild'),
              icon: require('@/assets/img/helpBuild.svg'),
            },
            {
              content: _vm.$t('online.enableInstitutionsTo'),
              icon: require('@/assets/img/enableInstitutionsTo.svg'),
            },
            {
              content: _vm.$t('online.supportOpenness'),
              icon: require('@/assets/img/macauTelecomCloudServices.svg'),
            },
          ]}})],1),_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"center","justify-content":"center"}},[_c('content-img',{attrs:{"image":require('@/assets/img/train-admin.png'),"title":_vm.$t('field.administratorApp'),"descs":[_vm.$t('content.importantInformation')],"direction":"RTL","alignValue":'center',"features":[
            {
              content: _vm.$t('content.controllableAndTraceable'),
              icon: require('@/assets/img/train-admin-icon1.svg'),
            },
            {
              content: _vm.$t('content.provideDataSupport'),
              icon: require('@/assets/img/train-admin-icon2.svg'),
            },
            {
              content: _vm.$t('content.improveTheQualityOfInstitutionalServices'),
              icon: require('@/assets/img/train-admin-icon3.svg'),
            },
          ]}})],1),_c('desc-content-list',{attrs:{"content":_vm.vxContent}}),_c('customization-area'),_c('package-list',{attrs:{"title":_vm.$t('online.chooseTheSolutionThatSuitsYou'),"descs":[_vm.$t('online.weUnderstandDifferentScales')],"features":[
          {
            packageId: 0,
            title: _vm.$t('online.standardSinglePlayerExperienceVersion'),
            content: _vm.$t('online.atLowerCost'),
            icon: require('@/assets/img/single-person.png'),
            people: [1, 1, 20, 1],
            mop: 149,
            duration: 2,
            icons: [
              {
                name: require('@/assets/img/orange-pc.svg'),
                content: _vm.$t('online.webManagementEnd'),
              },
            ],
            descId: 0,
            desc: [
              {
                content: _vm.$t('onlineContent.companyManagement'),
                haveLength: 0,
              },
              {
                content: _vm.$t('onlineContent.courseManagement'),
                haveLength: 2,
                children: [
                  {
                    have: 1,
                    specialCase: 1,
                    content: _vm.$t('onlineContent.chooseOne') + '\n -' + _vm.$t('onlineContent.standardCourses') + '\n -' + _vm.$t('onlineContent.elasticCourses'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.attendance'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.postClassReportingFunction'),
                  },

                  {
                    have: 2,
                    content: _vm.$t('onlineContent.onlineCourse'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.paidVideoCourses'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.selfServiceAttendanceAPPLeaveRequest'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.studentManagement'),
                haveLength: 3,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.studentProfile'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.studentOrderManagement'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.studentClassRecord'),
                  },

                  {
                    have: 2,
                    content: _vm.$t('onlineContent.complaintManagement'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.paymentMethod'),
                haveLength: 2,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.offlinePaymentManagement'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.invoiceManagement'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.onlinePayment'),
                  },
                ],
              },

              {
                content: _vm.$t('onlineContent.clientUsage'),
                have: 1,
                haveLength: 1,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.webManagementEnd'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.webApp'),
                  },

                  {
                    have: 2,
                    content: _vm.$t('onlineContent.studentApp'),
                  },

                  {
                    have: 2,
                    content: _vm.$t('onlineContent.companyStudentApp'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.vxApp'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.mentorManagement'),
                have: 2,
                haveLength: -1,
                children: [
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.mentorProfile'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.mentorSalesStatistics'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.salaryCalculation'),
                  },
                ],
              },

              {
                content: _vm.$t('onlineContent.communicationManagement'),
                have: 2,
                haveLength: -1,
                children: [
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.companyChatRoom'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.notice'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.chatRecordAuditFunction'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.promotionFunction'),
                have: 2,
                haveLength: -1,
                children: [
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.courseSalesLinkBrochure'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.exclusiveTrialCoursePromotionPage'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.exclusiveTrialCoursePromotionPagePosterDesign'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.companyExclusiveMall'),
                  },
                ],
              },
            ],
          },
          {
            packageId: 1,
            title: _vm.$t('online.multiplayerManagementProfessionalEdition'),
            content: _vm.$t('online.analyzeStudentActivityLevel'),
            num: 3,
            mop: 499,
            people: [5, 20, 100, 3],
            platformFees: 0,
            icon: require('@/assets/img/multiple-people.png'),
            duration: 4,
            icons: [
              {
                name: require('@/assets/img/orange-pc.svg'),
                content: _vm.$t('online.webManagementEnd'),
              },
              {
                name: require('@/assets/img/public-app.svg'),
                content: _vm.$t('online.publicAPP'),
              },
              {
                name: require('@/assets/img/orange-link.svg'),
                content: _vm.$t('online.weChatMiniProgram'),
              },
            ],
            descId: 1,
            desc: [
              {
                content: _vm.$t('onlineContent.companyManagement'),
                haveLength: 0,
              },
              {
                content: _vm.$t('onlineContent.courseManagement'),
                haveLength: 6,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.standardCourses'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.elasticCourses'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.attendance'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.postClassReportingFunction'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.onlineCourse'),
                  },
                  {
                    have: 0,
                    content: _vm.$t('onlineContent.paidVideoCourses'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.selfServiceAttendanceAPPLeaveRequest'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.studentManagement'),
                haveLength: 4,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.studentProfile'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.studentOrderManagement'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.studentClassRecord'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.complaintManagement'),
                  },
                ],
              },

              {
                content: _vm.$t('onlineContent.paymentMethod'),
                haveLength: 2,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.offlinePaymentManagement'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.invoiceManagement'),
                  },
                  {
                    have: 0,
                    content: _vm.$t('onlineContent.onlinePayment'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.clientUsage'),
                have: 1,
                haveLength: 3,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.webManagementEnd'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.webApp'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.studentApp'),
                  },

                  {
                    have: 0,
                    content: _vm.$t('onlineContent.companyStudentApp'),
                  },
                  {
                    have: 0,
                    content: _vm.$t('onlineContent.vxApp'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.mentorManagement'),
                have: 1,
                haveLength: 3,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.mentorProfile'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.mentorSalesStatistics'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.salaryCalculation'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.communicationManagement'),
                have: 1,
                haveLength: 2,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.companyChatRoom'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.notice'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.chatRecordAuditFunction'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.promotionFunction'),
                have: 1,
                haveLength: 1,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.courseSalesLinkBrochure'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.exclusiveTrialCoursePromotionPage'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.exclusiveTrialCoursePromotionPagePosterDesign'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.companyExclusiveMall'),
                  },
                ],
              },
            ],
          },
          {
            packageId: 2,
            title: _vm.$t('online.supremeEdition'),
            content: _vm.$t('online.adoptingPrivateCloudDeploymentMethod'),
            num: 10,
            mop: 899,
            people: [10, 50, 200, 10],
            icon: require('@/assets/img/private-institutions.png'),
            duration: 6,
            icons: [
              {
                name: require('@/assets/img/orange-pc.svg'),
                content: _vm.$t('online.webManagementEnd'),
              },
              {
                name: require('@/assets/img/private-app.svg'),
                content: _vm.$t('online.privateAPP'),
              },
              {
                name: require('@/assets/img/orange-link.svg'),
                content: _vm.$t('online.weChatMiniProgram'),
              },
              {
                name: require('@/assets/img/orange-four.svg'),
                content: _vm.$t('online.campusManagement'),
              },
              {
                name: require('@/assets/img/orange-customer-service.svg'),
                content: _vm.$t('online.aiCustomerService'),
              },
              {
                name: require('@/assets/img/orange-clound.svg'),
                content: _vm.$t('online.privateCloudDeployment'),
              },
            ],

            descId: 2,
            desc: [
              {
                content: _vm.$t('onlineContent.companyManagement'),
                haveLength: 0,
              },
              {
                content: _vm.$t('onlineContent.courseManagement'),
                haveLength: 6,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.standardCourses'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.elasticCourses'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.attendance'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.postClassReportingFunction'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.onlineCourse'),
                  },
                  {
                    have: 0,
                    content: _vm.$t('onlineContent.paidVideoCourses'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.selfServiceAttendanceAPPLeaveRequest'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.studentManagement'),
                haveLength: 4,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.studentProfile'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.studentOrderManagement'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.studentClassRecord'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.complaintManagement'),
                  },
                ],
              },

              {
                content: _vm.$t('onlineContent.paymentMethod'),
                haveLength: 2,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.offlinePaymentManagement'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.invoiceManagement'),
                  },
                  {
                    have: 0,
                    content: _vm.$t('onlineContent.onlinePayment'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.clientUsage'),
                have: 1,
                haveLength: 4,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.webManagementEnd'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.webApp'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.studentApp'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.companyStudentApp'),
                  },
                  {
                    have: 0,
                    content: _vm.$t('onlineContent.vxApp'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.mentorManagement'),
                have: 1,
                haveLength: 3,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.mentorProfile'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.mentorSalesStatistics'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.salaryCalculation'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.communicationManagement'),
                have: 1,
                haveLength: 3,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.companyChatRoom'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.notice'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.chatRecordAuditFunction'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.promotionFunction'),
                have: 1,
                haveLength: 2,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.courseSalesLinkBrochure'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.exclusiveTrialCoursePromotionPage'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.exclusiveTrialCoursePromotionPagePosterDesign'),
                  },
                  {
                    have: 2,
                    content: _vm.$t('onlineContent.companyExclusiveMall'),
                  },
                ],
              },
            ],
          },
          {
            packageId: 3,
            title: _vm.$t('online.supremeEdition') + '+',
            content: _vm.$t('online.adoptingPrivateCloudDeploymentMethod'),
            num: 10,
            mop: 1299,
            people: [20, 100, 300, 10],
            platformFees: 0,
            icon: require('@/assets/img/ultimate-edition+.png'),
            duration: 6,
            descId: 3,
            icons: [
              {
                name: require('@/assets/img/orange-pc.svg'),
                content: _vm.$t('online.webManagementEnd'),
              },
              {
                name: require('@/assets/img/private-app.svg'),
                content: _vm.$t('online.privateAPP'),
              },
              {
                name: require('@/assets/img/orange-link.svg'),
                content: _vm.$t('online.weChatMiniProgram'),
              },
              {
                name: require('@/assets/img/orange-four.svg'),
                content: _vm.$t('online.campusManagement'),
              },
              {
                name: require('@/assets/img/orange-customer-service.svg'),
                content: _vm.$t('online.aiCustomerService'),
              },
              {
                name: require('@/assets/img/orange-clound.svg'),
                content: _vm.$t('online.privateCloudDeployment'),
              },
            ],
            desc: [
              {
                content: _vm.$t('onlineContent.companyManagement'),
                haveLength: 0,
              },
              {
                content: _vm.$t('onlineContent.courseManagement'),
                haveLength: 6,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.standardCourses'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.elasticCourses'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.attendance'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.postClassReportingFunction'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.onlineCourse'),
                  },
                  {
                    have: 0,
                    content: _vm.$t('onlineContent.paidVideoCourses'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.selfServiceAttendanceAPPLeaveRequest'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.studentManagement'),
                haveLength: 4,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.studentProfile'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.studentOrderManagement'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.studentClassRecord'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.complaintManagement'),
                  },
                ],
              },

              {
                content: _vm.$t('onlineContent.paymentMethod'),
                haveLength: 2,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.offlinePaymentManagement'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.invoiceManagement'),
                  },
                  {
                    have: 0,
                    content: _vm.$t('onlineContent.onlinePayment'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.clientUsage'),
                have: 1,
                haveLength: 4,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.webManagementEnd'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.webApp'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.studentApp'),
                  },

                  {
                    have: 1,
                    content: _vm.$t('onlineContent.companyStudentApp'),
                  },
                  {
                    have: 0,
                    content: _vm.$t('onlineContent.vxApp'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.mentorManagement'),
                have: 1,
                haveLength: 3,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.mentorProfile'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.mentorSalesStatistics'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.salaryCalculation'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.communicationManagement'),
                have: 1,
                haveLength: 3,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.companyChatRoom'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.notice'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.chatRecordAuditFunction'),
                  },
                ],
              },
              {
                content: _vm.$t('onlineContent.promotionFunction'),
                have: 1,
                haveLength: 4,
                children: [
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.courseSalesLinkBrochure'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.exclusiveTrialCoursePromotionPage'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.exclusiveTrialCoursePromotionPagePosterDesign') + _vm.$t('onlineContent.1freeSheetPerMonth'),
                  },
                  {
                    have: 1,
                    content: _vm.$t('onlineContent.companyExclusiveMall'),
                  },
                ],
              },
            ],
          },
        ]}})],1),_c('v-scale-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"},{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","color":"secondary"},on:{"click":_vm.toTop}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1)],1),_c('foote')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }